import React, { useState, useRef, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import '../App.css';
// import logo from "../Components/assets/img/logo_readindia.png"
import logo from "../Components/assets/img/nlogo-1.png"
// import logo from "../Components/assets/img/logo.png"
import { Link } from "react-router-dom";
// import Dropdown from 'react-bootstrap/Dropdown';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {getHeader, getNews} from '../store/home';

function Header() {

//REDUCER START
const dispatch = useDispatch();

useEffect(() => {
  dispatch(getHeader());
},[]);

const header = useSelector(state => state.home.Header);
// console.log('zhed', header?.[0]?.title_main);
// console.log('zhead', header?.[0]);

// console.log('zhead',team?.teamlists?.['0']?.name);
//REDUCER END

  useEffect(() => {
    const tickerElement = document.getElementById('ticker');
    const tickerWidth = tickerElement.offsetWidth;
    const containerWidth = tickerElement.parentNode.offsetWidth;

    const animationDuration = (tickerWidth + containerWidth) / 50; 

    tickerElement.style.animationDuration = `${animationDuration}s`;
  }, []);


  
  const [showNav, setShowNav] = useState(false);

  // useEffect(() => {
  //   setShowNav(true);
  // }, []);

//TICKER-NEWS AUTO PLAY START
const tickerRef = useRef(null);
const [isHovered, setHovered] = useState(false);

const handleMouseEnter = () => {
  setHovered(true);
};

const handleMouseLeave = () => {
  setHovered(false);
};
//TICKER-NEWS AUTO PLAY END



//news start
useEffect(() => {
  dispatch(getNews());
},[]);
// const stat = useSelector(state => state.home);
const news = useSelector(state => state.home.News);
console.log('news', news);
//news end



  // Animate header start
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showSecondHeader, setShowSecondHeader] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.scrollY || document.documentElement.scrollTop;
      setScrollPosition(currentPosition);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  

  const navbarStyle = {
    background: scrollPosition > 100 ? 'linear-gradient(to right, #020e1a, #185490)' : 'transparent',
    position: scrollPosition < 100 ? 'fixed' : '',
    top: scrollPosition > 100 ? '0' : '40px',
    zIndex: scrollPosition > 100 ? '999' : '999',
    transition: 'background-color 0.3s ease',
  };
  
// Animate header end

//   Mobile menu start
const [selectedCategory, setSelectedCategory] = useState(null);
const [isOpen, setIsOpen] = useState(false);

const toggleMenu = () => {
  setIsOpen(!isOpen);
};
//   Mobile menu end


  
  return (
    <div>
      <>
      
      {/* top header start*/}
      <div className={`ticker-news ${isHovered ? 'ticker-paused' : ''}`}>
			<span></span>
			<div className="tickercontainer"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      >
				{/* <div className="ticker-mask">
          <ul ref={tickerRef} id="ticker" className="js-hidden newsticker">
            <li>
              <Link to="https://readindia.info/reading-is-an-experience-books-are-your-never-failing-friends-sudha-murthy-author/" 
              title="Welcome to ReadIndiaCelebration: India’s Biggest Reading Festival…….               #ReadIndiaCelebration2023 (International) – Connecting a Billion Readers, is a Transformative Reading and Leadership Experience for Students –  Now open for registrations! Hurry up and register now!">Welcome to ReadIndiaCelebration: India’s Biggest Reading Festival…….               #ReadIndiaCelebration2023 (International) – Connecting a Billion Readers, is a Transformative Reading and Leadership Experience for Students –  Now open for registrations! Hurry up and register now!
              </Link>
            </li>						
            <li>
              <Link to="https://readindia.info/latest-news-6/" title="“Reading is an experience and books are your never-failing friends” (Sudha Murthy, Author &amp; Philanthropist)">“Reading is an experience and books are your never-failing friends” (Sudha Murthy, Author &amp; Philanthropist)
              </Link>
            </li>						
            <li>
              <Link to="https://readindia.info/xyz/" 
              title="Reading is the seeding for knowledge and innovation">Reading is the seeding for knowledge and innovation
              </Link>
            </li>						
            <li>
              <Link to="https://readindia.info/hello-world-6/" 
              title="“Education that does not enable independent thinking is devoid of something fundamental.”">“Education that does not enable independent thinking is devoid of something fundamental.”</Link>
            </li>					
          </ul>
				</div> */}
        <div className="ticker-mask">
          <ul ref={tickerRef} id="ticker" className="js-hidden newsticker">
            <li style={{color:'white'}}>
             {news}
            </li>						
            {/* <li>
              <Link to="https://readindia.info/latest-news-6/" title="“Reading is an experience and books are your never-failing friends” (Sudha Murthy, Author &amp; Philanthropist)">“Reading is an experience and books are your never-failing friends” (Sudha Murthy, Author &amp; Philanthropist)
              </Link>
            </li>						
            <li>
              <Link to="https://readindia.info/xyz/" 
              title="Reading is the seeding for knowledge and innovation">Reading is the seeding for knowledge and innovation
              </Link>
            </li>						
            <li>
              <Link to="https://readindia.info/hello-world-6/" 
              title="“Education that does not enable independent thinking is devoid of something fundamental.”">“Education that does not enable independent thinking is devoid of something fundamental.”</Link>
            </li>					 */}
          </ul>
				</div>
			</div>
		</div>


      </>

      <>
      <div>
  <header id="headerMain" style={navbarStyle}>
    <nav className="navbar navbar-expand-lg fixed-top navbar-light animated fadeIn p-0" style={navbarStyle}>
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img
            alt="Read India Celebration"
            title="Read India Celebration"
            data-src={logo}
            className="img-fluid lazyloaded"
            src={logo}
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="/navbarScroll"
          aria-controls="navbarScroll"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={toggleMenu}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${isOpen ? "show" : ""}`} id="navbarScroll">
          <ul className="navbar-nav ms-auto my-2 my-lg-0 navbar-nav-scroll" style={{ columnGap: '25px' }}>
            {header?.map((menuItem, index) => (
              <li className="nav-item dropdown" key={index}>
                {menuItem.sub_array && menuItem.sub_array.length > 0 ? (
                
                <div className="dropdown">
              <a className="nav-link dropdown-toggle" href={menuItem.menu_url} id={`menu${index}`} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {menuItem.title_main}
              </a>
              <ul className="dropdown-menu" aria-labelledby={`menu${index}`}>
                {/* Render submenu items */}
                {menuItem.sub_array.map((subMenuItem) => (
                  <li key={subMenuItem.id}>
                    <a className="dropdown-item" href={subMenuItem.url}>{subMenuItem.title}</a>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            // Render the main menu item without dropdown if no submenu exists
            <a className="nav-link" href={menuItem.menu_url}>
              {menuItem.title_main}
            </a>
          )}
        </li>
      ))}
            <li
              id="menu-item-3438"
              className="btn-high menu-item menu-item-type-custom menu-item-object-custom menu-item-3438 nav-item"
              style={{ backgroundColor: '#f07f2d', borderRadius: '4px', boxShadow: '0 2px 6px 1px #400505' }}
            >
              <a href="https://readindia.info/cms/" target="_blank"  className="nav-link">Login / Signup</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</div>


      </>
</div>
   
  )
}

export default Header;
