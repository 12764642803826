import React from 'react'

const Iconbar = () => {
  return (
    <div>
       <div className="icon-bar">
          <a href="https://www.facebook.com/ReadIndiaTeam" target="_blank" className="facebook"><i class="fa fa-facebook-official" aria-hidden="true"></i></a> 
          <a href="https://twitter.com/readindiateam" target="_blank" className="twitter"><i class="fa fa-twitter-square" aria-hidden="true"></i></a> 
          <a href="https://www.instagram.com/readindiacelebration/" target="_blank" className="instagram"><i class="fa fa-instagram" aria-hidden="true"></i></a> 
          <a href="https://www.youtube.com/channel/UCYOHD0v6J_7rkRpvFS69hrA/videos" target="_blank" className="youtube"><i class="fa fa-youtube-play" aria-hidden="true"></i></a>
          </div>
    </div>
  )
}

export default Iconbar