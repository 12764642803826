import { createSlice } from "@reduxjs/toolkit";
import { ricApi } from './axios';

//show the loader
var userDetails = localStorage.getItem("userDetails");
var data = JSON.parse(userDetails);
var user_id = data?.ID;

const intialHomeState = {
    HomeRead: [], home_array:[], GalleryImage: [], GalleryVideo: [], Team: [], Initiative: [], About: [], Header: [], Footer: [], about_banner: [], loaderStatus: false, InitiativePages: [], BannerPages: [], News: [], Disclaimer:[],
    loader : false,
    update_profile_loader : false,
    update_password_loader : false,disBanner:[], disBnrImg:[],bannrpath:{},initiative_subpage:[], Contact: [], contact_banner: [], contact_submit: [], othe_page_contents: [], newslatter_submit: [], FooterLink: []
}

const homeSlice = createSlice({
    name: 'home',
    initialState: intialHomeState,
    reducers: {

        getHomeData(state, action) {
            state.data = action.payload;
            state.loaderStatus = action.payload.loaderStatus;
            state.loaderStatus = false;
        },
        HomeReadSuccess(state, action) {
            state.HomeRead = action.payload
            state.loaderStatus = false;
        },
        home_rec(state, action){
           
            state.home_array = action.payload;
            //console('ddd',action.payload);
        },
        GalleryImageSuccess(state, action) {
            state.GalleryImage = action.payload
            state.loaderStatus = false;
        },
        GalleryVideoSuccess(state, action) {
            state.GalleryVideo = action.payload
            state.loaderStatus = false;
        },
       TeamSuccess(state, action) {
            state.Team = action.payload
            state.loaderStatus = false;
        },
        InitiativeSuccess(state, action) {
             state.Initiative = action.payload
             state.loaderStatus = false;
         },
         AboutSuccess(state, action) {
             state.About = action.payload
             state.loaderStatus = false;
         },
         HeaderSuccess(state, action) {
             state.Header = action.payload
         },
         FooterSuccess(state, action) {
            state.Footer = action.payload
        },
         FooterLinkSuccess(state, action) {
            state.FooterLink = action.payload
        },
        Aboutbanner(state, action){
            state.about_banner = action.payload
        },
        updateLoaderStatus(state, action) {
            state.loaderStatus = action.payload
        },
        InitiativePagesSuccess(state, action) {
            state.InitiativePages = action.payload
        },
        BannerPagesSuccess(state, action) {
            state.BannerPages = action.payload
        },
        NewsSuccess(state, action) {
            state.News = action.payload
        },
        DisclaimerSuccess(state, action) {
            state.Disclaimer = action.payload
        },
        Disclaimerbanner(state, action) {
            state.disBanner = action.payload
        },
        bannerpathSuccess(state, action){
            state.bannrpath = action.payload
        },
        iniSubpages(state, action){
            state.initiative_subpage = action.payload
        },
        ContactSuccess(state, action) {
            state.Contact = action.payload
            state.loaderStatus = false;
        },
        Contactbanner(state, action){
            state.contact_banner = action.payload
        },
        ContactSuccessSubmit (state, action){
            state.contact_submit = action.payload
            state.loaderStatus = false;
        },
        othepageContents(state, action){
            state.othe_page_contents = action.payload
            console.log(' state.othe_page_contents');
        },
        NewslatterSuccessSubmit (state, action){
            state.newslatter_submit = action.payload
            state.loaderStatus = false;
        },

    }
})


export const homeActions = homeSlice.actions;


export const getHomeRead = () => {
    return async (dispatch) => { 
        dispatch(homeActions.updateLoaderStatus(true))
        try{
            await ricApi.get('/homepage-web').then(response => {
                if(response.data.status == "success")
                {
                    dispatch(homeActions.HomeReadSuccess(response.data.data))
                } 
                if(response.data.status == "error"){
                    dispatch(homeActions.HomeReadFailure(response.data.msg))
                }
                dispatch(homeActions.updateLoaderStatus(false))
            })
        }
        catch (e) {
            return 
        }
    }
}
export const getHomepage = () => {
    return async (dispatch) => {
        try {
            await ricApi.get("/homepage-web")
                .then((response) => {
                    dispatch(homeActions.home_rec(response.data));
                });
        } 
        catch (e) {
            return;
        }
    };
};
export const getGalleryImage = () => {
    return async (dispatch) => { 
        dispatch(homeActions.updateLoaderStatus(true))
        try{
            await ricApi.post('/gallery-image').then(response => {
                if(response.data.status == "success")
                {
                    dispatch(homeActions.GalleryImageSuccess(response.data.data))
                } 
                if(response.data.status == "error"){
                    dispatch(homeActions.GalleryImageFailure(response.data.msg))
                }
                dispatch(homeActions.updateLoaderStatus(false))
            })
        }
        catch (e) {
            return 
        }
    }
}
export const getGalleryVideo = () => {
    return async (dispatch) => { 
        dispatch(homeActions.updateLoaderStatus(true))
        try{
            await ricApi.post('/gallery-video-web').then(response => {
                if(response.data.status == "success")
                {
                    dispatch(homeActions.GalleryVideoSuccess(response.data.data))
                } 
                if(response.data.status == "error"){
                    dispatch(homeActions.GalleryVideoFailure(response.data.msg))
                }
                dispatch(homeActions.updateLoaderStatus(false))
            })
        }
        catch (e) {
            return 
        }
    }
}
export const getTeam = () => {
    return async (dispatch) => { 
        dispatch(homeActions.updateLoaderStatus(true))
        try{
            await ricApi.get('/team').then(response => {
                if(response.data.status == "success")
                {
                    dispatch(homeActions.TeamSuccess(response.data.data))
                } 
                if(response.data.status == "error"){
                    dispatch(homeActions.TeamFailure(response.data.msg))
                }
                dispatch(homeActions.updateLoaderStatus(false))
            })
        }
        catch (e) {
            return 
        }
    }
}
export const getInitiative = () => {
    return async (dispatch) => { 
        dispatch(homeActions.updateLoaderStatus(true))
        try{
            await ricApi.get('/initiatives').then(response => {
                if(response.data.status == "success")
                {
                    dispatch(homeActions.InitiativeSuccess(response.data.data))
                } 
                if(response.data.status == "error"){
                    dispatch(homeActions.InitiativeFailure(response.data.msg))
                }
                dispatch(homeActions.updateLoaderStatus(false))
            })
        }
        catch (e) {
            return 
        }
    }
}
export const getAbout = () => {
    return async (dispatch) => { 
        dispatch(homeActions.updateLoaderStatus(true))
        try{
            await ricApi.get('/about').then(response => {
                if(response.data.status == "success")
                {
                  
                     dispatch(homeActions.AboutSuccess(response.data?.data['0']['contents']))
                     dispatch(homeActions.Aboutbanner(response.data))
                } 
                if(response.data.status == "error"){
                  //  dispatch(homeActions.AboutSuccess(response.data.msg))
                }
                dispatch(homeActions.updateLoaderStatus(false))
            })
        }
        catch (e) {
            return 
        }
    }
}
// dispatch(homeActions.AboutSuccess(response.data.data))
//console.log('hii',response.data.data['0']['contents']);
export const getHeader = () => {
    return async (dispatch) => { 
        try{
            await ricApi.get('/header-nav').then(response => {
                if(response.data.status == "success")
                {
                    dispatch(homeActions.HeaderSuccess(response.data.header_menu))
                } 
                if(response.data.status == "error"){
                    dispatch(homeActions.HeaderFailure(response.data.msg))
                }
            })
        }
        catch (e) {
            return 
        }
    }
}
export const getFooter = () => {
    return async (dispatch) => { 
        try{
            await ricApi.get('/header-nav').then(response => {
                if(response.data.status == "success")
                {
                    dispatch(homeActions.FooterSuccess(response.data.header_menu))
                } 
                if(response.data.status == "error"){
                    dispatch(homeActions.FooterFailure(response.data.msg))
                }
            })
        }
        catch (e) {
            return 
        }
    }
}
export const getFooterLinks = () => {
    return async (dispatch) => { 
        try{
            await ricApi.get('/footer-nav').then(response => {
                if(response.data.status == "success")
                {
                    dispatch(homeActions.FooterLinkSuccess(response.data.footer_menu))
                } 
                if(response.data.status == "error"){
                    dispatch(homeActions.FooterLinkFailure(response.data.msg))
                }
            })
        }
        catch (e) {
            return 
        }
    }
}
export const getInitiativePages = () => {
    return async (dispatch) => { 
        try{
            await ricApi.post('/initiative-pages').then(response => {
                if(response.data.status == "success")
                {
                    dispatch(homeActions.InitiativePagesSuccess(response.data))
                } 
                if(response.data.status == "error"){
                    dispatch(homeActions.InitiativePagesFailure(response.data.msg))
                }
            })
        }
        catch (e) {
            return 
        }
    }
}
export const getBannerPages = () => {
    return async (dispatch) => { 
        try{
            await ricApi.post('/banner-pages').then(response => {
                if(response.data.status == "success")
                {
                    dispatch(homeActions.BannerPagesSuccess(response.data))
                } 
                if(response.data.status == "error"){
                    dispatch(homeActions.BannerPagesFailure(response.data.msg))
                }
            })
        }
        catch (e) {
            return 
        }
    }
}
export const getNews = () => {
    return async (dispatch) => { 
        try{
            await ricApi.post('/news-ticker').then(response => {
                if(response.data.status == "success")
                {
                    dispatch(homeActions.NewsSuccess(response.data.news_description))
                } 
                if(response.data.status == "error"){
                    dispatch(homeActions.NewsFailure(response.data.msg))
                }
            })
        }
        catch (e) {
            return 
        }
    }
}
export const getDisclaimer = () => {
    return async (dispatch) => { 
        try{
            await ricApi.get('/disclaimer').then(response => {
                if(response.data.status == "success")
                {
                    dispatch(homeActions.DisclaimerSuccess(response.data.data[0]?.contents));
                    dispatch(homeActions.Disclaimerbanner(response.data?.banner));
                    dispatch(homeActions.bannerpathSuccess(response.data?.banner_path));
                    //dispatch(homeActions.Disclaimerbnrimg(response.data.banner['0']));
                    // dispatch(homeActions.DisclaimerSuccess(response.data.banner['0']?.img_desc));
                   // console.log('disban', response.data.banner[0]?.img_desc);
                   // console.log('disban', response.data.banner['0']?.img_desc);
                } 
                if(response.data.status == "error"){
                    dispatch(homeActions.DisclaimerFailure(response.data.msg))
                }
            })
        }
        catch (e) {
            return 
        }
    }
}
   
export const getInitiativePageDetails =(payload)=>{
    return async (dispatch) => { 
        dispatch(homeActions.updateLoaderStatus(true))
        try{
            await ricApi.post('/initiative-pages',{code:payload}).then(response => {
                if(response.data.status == "success")
                {
                 
                     dispatch(homeActions.iniSubpages(response.data?.data))
                     dispatch(homeActions.bannerpathSuccess(response.data?.banner_path));
                } 
                
                dispatch(homeActions.updateLoaderStatus(false))
            })
        }
        catch (e) {
            return 
        }
    }
}

export const getContact = (formData) => {
    return async (dispatch) => {
      dispatch(homeActions.updateLoaderStatus(true));
      try {
        // Fetch contact data
        await ricApi.get('/contact-us').then((response) => {
          if (response.data.status === 'success') {
            console.log('pramodini',response.data?.banner);
            dispatch(homeActions.ContactSuccess(response.data?.data));
            dispatch(homeActions.Contactbanner(response.data?.banner));
           // console.log(response.data?.banner_path);
            dispatch(homeActions.bannerpathSuccess(response.data?.banner_path));
          }
          if (response.data.status === 'fail') {
            dispatch(homeActions.ContactSuccess(response.data.msg));
            
          }
        });
        dispatch(homeActions.updateLoaderStatus(false));
      } catch (e) {
        console.error(e);
        // Handle the error (e.g., show error message)
        dispatch(homeActions.updateLoaderStatus(false));
      }
    }
  }

  export const saveContact = (formData) => {
    return async (dispatch) => {
      dispatch(homeActions.updateLoaderStatus(true));
      try {
        // Send email
        await ricApi.post('/save-contact', formData).then((response) => {
          if (response.data.status == 'success'){
                dispatch(homeActions.ContactSuccessSubmit(response.data.msg));
          }
        });
  
        dispatch(homeActions.updateLoaderStatus(false));
      } catch (e) {
        console.error(e);
        dispatch(homeActions.updateLoaderStatus(false));
      }
    }
  }

  export const getRandompage = (url) => {
   
    return async (dispatch) => {
      dispatch(homeActions.updateLoaderStatus(true));
      try {
        //const response = await ricApi.get(`https://readindia.info/ric/ReadIndiaOnline/${url.title_code}`);
        const response = await ricApi.get(`${url.title_code}`);
        if (response.data.status === 'success') {
         
          dispatch(homeActions.othepageContents(response.data?.data));
          dispatch(homeActions.Contactbanner(response.data?.banner));
          dispatch(homeActions.bannerpathSuccess(response.data?.banner_path));
        }
        dispatch(homeActions.updateLoaderStatus(false));
      } catch (error) {
        console.error('Error:', error);
        dispatch(homeActions.updateLoaderStatus(false));
      }
    };
  };
  
  export const saveNews = (formData) => {
    return async (dispatch) => {
      dispatch(homeActions.updateLoaderStatus(true));
      try {
        // Send email
        await ricApi.post('/newsletter', formData).then((response) => {
          if (response.data.status == '1'){
                dispatch(homeActions.NewslatterSuccessSubmit(response.data.msg));
          }
        });
  
        dispatch(homeActions.updateLoaderStatus(false));
      } catch (e) {
        console.error(e);
        dispatch(homeActions.updateLoaderStatus(false));
      }
    }
  }
  
export default homeSlice.reducer;