import React, { useState, useEffect } from "react";
import AboutSVG from "../Components/assets/img/about.svg";
import { Fade, Slide, Bounce, Zoom } from "react-awesome-reveal";
import { getAbout } from "../store/home";
import { useDispatch, useSelector } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";




const About = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAbout());
  }, []);
  const st = useSelector(state => state.home);
  const about = useSelector(state => state.home.About);
  const banners = useSelector(state => state.home.about_banner);


  const siteUrl = window.location.href;

  console.log('xvccfdh',st.loaderStatus);
  return (
    <>
      {st.loaderStatus ? (
        <div className="loader-container">
        <BeatLoader loading={st.loaderStatus} size={50} color="#36d7b7" />
      </div>
      ) : (
        <>
          <div>
  
            {/* Slideshow */}
            <div className="slide-wrap inner">
              <div className="container">
                {/* Mapping over banners array */}
                {banners?.banner?.map((ban, pageIndex) => (
                  <div className="flex-grid" key={pageIndex}>
                    <Slide direction="up">
                      <div
                        className="page-heading wow fadeInUp  text-color-p"
                        dangerouslySetInnerHTML={{ __html: ban.img_desc }}
                      ></div>
                    </Slide>
                    <Zoom>
                      <div className="page-img wow fadeInUp">
                        <img
                          src={banners.banner_path + ban.name}
                          className="img-fluid"
                          alt="about us"
                          title="about us"
                        />
                      </div>
                    </Zoom>
                  </div>
                ))}
  
              
              </div>
            </div>
  
            {/* About section */}
            <section className="section-wrapper page-wrap">
              {about && (
                <div
                  className="container"
                  style={{'textAlign': 'justify'}}
                  id="aboutContent"
                  dangerouslySetInnerHTML={{ __html: about }}
                ></div>
              )}
            </section>
          </div>
        </>
      )}
    </>
  )};
  

export default About;
