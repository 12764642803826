import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDisclaimer } from '../store/home';


const Disclaimer = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDisclaimer());
  }, []);

  const stata = useSelector(state => state.home);
  const disclaimer = useSelector(state => state.home.Disclaimer);
  const disBanner = useSelector(state => state.home?.disBanner);
  const ban = disBanner['0'];

  const bannrpath = useSelector(state => state.home.bannrpath);


  return (
    <div>
      <div>
        <div className="slide-wrap inner">
          <div className="container">
            <div className="flex-grid">
              <div className="page-heading wow fadeInUp animated" style={{ "visibility": "visible", "animation-name": "fadeInUp" }}>
                <p dangerouslySetInnerHTML={{ __html: ban?.img_desc }}></p>
              </div>
              <div className="page-img wow fadeInUp animated" style={{ "visibility": "visible", "animation-name": "fadeInUp" }}>
                <img alt="about us"
                  title="about us" data-src={bannrpath + ban?.name}
                  className="img-fluid lazyloaded" src={bannrpath + ban?.name} />
                <noscript>
                  <img src={bannrpath + ban?.name}
                    className="img-fluid" alt="about us" title="about us" />
                </noscript>
              </div>
            </div>

          </div>
        </div>
        <div class="container disclaimertext" >
          <p dangerouslySetInnerHTML={{ __html: disclaimer }}></p>

        </div>
      </div>
    </div>
  )

}

export default Disclaimer
