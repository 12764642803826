import React, { useEffect, useState, useCallback, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import pic1 from "../Components/assets/img/reading-student.png";
import BeatLoader from "react-spinners/BeatLoader";
import { useDispatch, useSelector } from 'react-redux';

import {getInitiativePageDetails } from '../store/home';

const Ric = () => {

  const st = useSelector(state => state.home);
  const dispatch = useDispatch();
    const filterUrl = window.location.pathname.split("initiatives/").pop();
    const ini_page = filterUrl.split("/");
    const title_code =  ini_page[0];
  
    useEffect(() => {
          dispatch(getInitiativePageDetails({ title_code }))
          
        }, [title_code])
  const insub = st.initiative_subpage;
  console.log('insub',insub);
  const disBanner = st.bannrpath;
  return (
    <>
    {st.loaderStatus ? (
        <div className="loader-container">
        <BeatLoader loading={st.loaderStatus} size={50} color="#36d7b7" />
      </div>
      ) : (
    <div>
         
        <div className="slide-wrap inner">
    <div className="container">
      <div className="flex-grid">
        <div className="page-heading wow fadeInUp animated text-color-p" style={{"visibility": "visible", "animation-name": "fadeInUp"}}
        dangerouslySetInnerHTML={{ __html:  insub?.banner_description }}
        >
          {/* <h3>#RIC2022-Connecting a Billion Readers!</h3>
          <div className="content">
            Reading is the seeding for knowledge and innovation that drive economies and individual success!         
          </div> */}
        </div>
        <div className="page-img wow fadeInUp animated" style={{"visibility": "visible", "animation-name": "fadeInUp"}}>
          <img alt="about us" title="about us" data-src={disBanner + insub?.banner}
          className="img-fluid lazyloaded" src={disBanner + insub?.banner}/>
          <noscript>
            <img src={disBanner + insub?.banner}
            className="img-fluid" alt="about us" title="about us"/>
        </noscript>
        </div>
      </div>
    </div>
    </div>

    <div className="container" dangerouslySetInnerHTML={{ __html:  insub?.contents }} style={{'textAlign': 'justify'}}>
    
    </div>
    </div>
      )}
      </>
  )
}

export default Ric