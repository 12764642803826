import React from 'react';
import './App.css';
import Footer from './Components/Footer';
import Header from './Components/Header';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import Partner from './Components/Partner';
import About from './Pages/About';
import Initiatives from './Pages/Initiatives';
import Gallery from './Pages/Gallery';
import Team from './Pages/Team';
import Contact from './Pages/Contact';
import International from './Pages/International';
import Ric from './Pages/Ric';
import Disclaimer from './Pages/Disclaimer';
import Iconbar from './Components/Iconbar';
import Pagecontent from './Pages/Pagecontent';
import Teachers from './Pages/Teachers';
// import GalleryImg from './Pages/GalleryImg';
// import GalleryVdo from './Pages/GalleryVdo';

function App() {
  return (
    // <Router basename={'/ric/ReadIndiaOnline'}>
     <Router basename={'/'}> 
    <Header/>
    <Iconbar/>
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/about" element={<About />} />
      <Route exact path="/initiatives" element={<Initiatives />} />
      <Route exact path="/gallery" element={<Gallery />} />
      {/* <Route exact path="/gallery/images" element={<GalleryImg />} /> */}
      {/* <Route exact path="/gallery/videos" element={<GalleryVdo />} /> */}
      <Route exact path="/team" element={<Team />} />
      <Route exact path="/contact-us" element={<Contact />} /> 
      <Route exact path="/home/*" element={<International />} />
      <Route exact path="/initiatives/*" element={<Ric />} />
      <Route exact path="/disclaimer" element={<Disclaimer />} />
      <Route exact path="/*" element={<Pagecontent />} />
      <Route exact path="/teachers" element={<Teachers />} />
    </Routes>
    <Partner />
<Footer />
   </Router>

   
  );
}

export default App;
