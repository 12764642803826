import React, { useState, useEffect } from "react";
 
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-autoplay.css";
import "lightgallery/css/lg-share.css";
import "lightgallery/css/lg-rotate.css";
 
// import plugins if you need
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import lgAutoplay from "lightgallery/plugins/autoplay";
import lgVideo from "lightgallery/plugins/video";
import lgShare from "lightgallery/plugins/share";
import lgRotate from "lightgallery/plugins/rotate";
 
import LightGallery from "lightgallery/react/Lightgallery.es5";
import GallerySVG from '../Components/assets/img/gallery.svg';
import { Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import axios from "axios";
import { slice } from 'lodash'
import { Fade, Slide, Bounce, Zoom } from "react-awesome-reveal";
import { getGalleryImage, getGalleryVideo } from "../store/home";
import { useDispatch, useSelector } from "react-redux";
import YouTube, { Options, OnReadyEvent } from 'react-youtube';
import BeatLoader from "react-spinners/BeatLoader";

const Gallery = () => {
// Reducer start
const dispatch = useDispatch();

useEffect(() => {
  dispatch(getGalleryImage());
}, []);
//Reducer end


const st = useSelector(state => state.home);
const galleryimage = useSelector(state => state.home.GalleryImage);
console.log('zxd', galleryimage);
console.log('zxg',galleryimage?.gallery?.['0']?.image);
console.log('gallerybnr',galleryimage?.banner?.banners['0']?.name);
console.log('gallerybnrpath',galleryimage?.banner?.banner_path);
console.log('galleryimg',galleryimage?.imagepath);

console.log('zab', galleryimage?.total_page_gallery);



const [currentPage, setCurrentPage] = useState(1);

const itemsPerPage = 15; // Adjust the number as per your requirement
const totalItems = galleryimage?.gallery?.length; // Total number of items
const totalPages = Math.ceil(totalItems / itemsPerPage);

const startIndex = (currentPage - 1) * itemsPerPage;
const endIndex = startIndex + itemsPerPage;


const handlePageChange = (selectedPage) => {
setCurrentPage(selectedPage);
};


//VIDEO REDUCER START

// Reducer start

  useEffect(() => {
    dispatch(getGalleryVideo());
  },[])
//Reducer end
// const state = useSelector(state => state.home)
const galleryvideos = useSelector((state) => state.home.GalleryVideo);
    // const galleryvideos =state.GalleryVideo;


    console.log('acc', galleryvideos);

    // Play button start
const onPlayerReady = (event) => {
  // access to player in all event handlers via event.target
  event.target.pauseVideo();
};

const opts = {
  height: '230',
  width: '350',
  // borderRadius: '10',
  playerVars: {
    // autoplay: 1,
  },
};


// const [currentPage, setCurrentPage] = useState(1);
const itemsPerPages = 6; 
const totalItem = galleryvideos?.videos?.length; // Total number of items
const totalPage = Math.ceil(totalItem / itemsPerPages);

const startIndexs = (currentPage - 1) * itemsPerPages;
const endIndexs = startIndexs + itemsPerPages;


// const handlePageChange = (selectedPage) => {
// setCurrentPage(selectedPage);
// };

//VIDEO REDUCER END

  return (
    <>
    {st.loaderStatus ? (
        <div className="loader-container">
        <BeatLoader loading={st.loaderStatus} size={50} color="#36d7b7" />
      </div>
      ) : (
    <div>
     
         {/* <!-- carousel --> */}
      <div className="slide-wrap inner">
        <div className="container">
          <div className="flex-grid">
            <Slide direction="up">
              <div className="page-heading wow fadeInUp">
                <h3>Gallery</h3>
                <div className="content" dangerouslySetInnerHTML={{ __html:  galleryimage?.banner?.banners['0']?.img_desc}}>
                 
                </div>
              </div>
            </Slide>
            <Zoom>
              <div className="page-img wow fadeInUp">
              {/* {`${galleryimage?.banner?.banner_path}${ galleryimage?.banner?.banners['0']?.name}`} */}
              
              {/* {`${initiative?.imagepath}${ member.image}`} */}
                <img
                  src={galleryimage?.banner?.banner_path + galleryimage?.banner?.banners['0']?.name}
                  className="img-fluid"
                  alt={galleryimage?.banner?.banners['0']?.img_alt}
                  title="GallerySVG"
                />
              </div>
            </Zoom>
          </div>
        </div>
      </div>
      {/* <!-- end --> */}



      <>
        {/* <!-- corporate --> */}
        <div className="section-wrapper">
          <div className="container">
            <h1 className="head1 text-center">Ric Gallery</h1>
            <div className="gallery-container" id="gallery-container">
              <LightGallery
                speed={200}
                plugins={[ lgThumbnail, lgZoom, lgShare, lgRotate, lgVideo, lgAutoplay, ]} >
                {galleryimage?.gallery?.slice(startIndex, endIndex).map((image, index) => (
                    <a
                      key={index}
                      data-lg-size="1400-1400"
                      className="gallery-item"
                      data-src={galleryimage?.imagepath + image.image}
                      data-sub-html="<h4>Heading Here</h4>"
                    >
                      <img
                        className="img-fluid"
                        src={galleryimage?.imagepath + image.image}
                        alt={`Gallery Image ${index}`}
                      />
                    </a>
                  ))}
              </LightGallery>
            </div>
          </div>


          <div>

          <ReactPaginate
          className="paginatedsgn"
      pageCount={totalPages}
      // marginPagesDisplayed={2}
      pageRangeDisplayed={galleryimage?.total_page_gallery}
      onPageChange={(data) => handlePageChange(data.selected + 1)}
      containerClassName={"paginationdesg"}
      activeClassName={"active-page"}
      />
      </div>
    </div>
  </>

  <>
  <p>&nbsp;</p>
    <h2 style={{'text-align': 'center'}}>Videos</h2>
    <p>&nbsp;</p>

    <>
 {/* <!-- corporate --> */}
  <div className="container">
    <div className="row" style={{ textAlign: 'center', 'paddingBottom': '3%' }}>
        {galleryvideos?.videos?.slice(startIndexs, endIndexs).map((video, index) => (
        <div className="col" key={video.id}>
          <div className="news-item">
            <YouTube
              videoId={video.video}
              // width="340"
              // height="230"
              opts={opts}
              onReady={onPlayerReady}
            />
          </div>
        </div>
      ))}
    </div>
  </div>

<div>

          <ReactPaginate
          className="paginatedsgn"
      pageCount={totalPage}
      // marginPagesDisplayed={2}
      pageRangeDisplayed={galleryvideos?.total_page_video}
      onPageChange={(data) => handlePageChange(data.selected + 1)}
      containerClassName={"paginationdesg"}
      activeClassName={"active-page"}
    />



          </div>
</>
  </>
</div>
      )}
      </>
  )
}

export default Gallery