import React from 'react'
import BeatLoader from "react-spinners/BeatLoader";
import { useDispatch, useSelector } from 'react-redux';

const International = () => {

  const st = useSelector(state => state.home);


  return (
    <>
    {st.loaderStatus ? (
        <div className="loader-container">
        <BeatLoader loading={st.loaderStatus} size={50} color="#36d7b7" />
      </div>
      ) : (
    <>
    <div>
    
        <div className="slide-wrap inner">
    <div className="container">
      <div className="flex-grid">
        <div className="page-heading wow fadeInUp animated" style={{"visibility": "visible", "animation-name": "fadeInUp"}}>
          <h3>#RIC2023 (International)</h3>
          <div className="content">
            Reading is Fun, Fashion, and Nutrition for the MIND!          
          </div>
        </div>
        <div className="page-img wow fadeInUp animated" style={{"visibility": "visible", "animation-name": "fadeInUp"}}>
          <img alt="about us" title="about us" data-src="https://readindia.info/wp-content/uploads/2022/02/RIC2022_Pic2-1.png" 
          className="img-fluid lazyloaded" src="https://readindia.info/wp-content/uploads/2022/02/RIC2022_Pic2-1.png"/>
          <noscript>
            <img src="https://readindia.info/wp-content/uploads/2022/02/RIC2022_Pic2-1.png" className="img-fluid" alt="international" title="international"/>
        </noscript>
        </div>
      </div>
    </div>
    </div>
    </div>

    {/* // about company */}
    <>
     <div className="container">
    <h4>&nbsp;</h4>
<h3><span style={{'color': '#000000'}}><em><strong>WELCOME TO INDIA’s BIGGEST READING FESTIVAL!!</strong></em></span></h3>
<p>&nbsp;</p>
<p><em>Read the participation process below before registering. Click here for <a href="https://readindia.info/cms/student-register">RIC 2023 Student Registrations; </a>&nbsp;Click here for </em><em><a href="https://readindia.info/cms/citizens-register">RIC 2023 Citizen Registrations</a></em></p>
<h5>&nbsp;</h5>
<h4><span style={{'color': '#ff9900'}}>#RIC 2023 (International): &nbsp;Transformative Readership &amp; Leadership Experience. Hurry up &amp; register now!</span></h4>
<p><span className="red-underline" data-startindex="0" data-endindex="1" data-paragraphid="5">Every successful person in the world is a reader and an independent thinker. Knowledge and creativity are sown in reading. (</span><a href="https://readindia.info/wp-content/uploads/2022/07/READ.-THINK.-ACT.-2022-Edition-v2.1.pdf">Download the free READ. THINK. ACT. eBook to know more!,</a> and for the hard copy purchase, <a href="https://www.hindueshop.com/product/read-think-act/">click here</a>). Reading diverse books opens up the mind and only a diverse &amp; open mind is a fertile ground for creativity to happen! Remember, Gyan (knowledge+knowing) is the essence of life.&nbsp; &nbsp; NO READING = NO SUCCESS, AND NO KNOWING = NO INSIGHT / INTELLIGENCE. RIC’s purpose is to raise awareness about the benefits of reading. According to research, practical reading (i.e., reading non-academic books, “Self,” and situations) and problem-solving skills contribute more than 50% to an individual’s success. Reading helps a person’s thinking come to life. A reading India is a knowledgeable, thinking, and innovative India. A knowledgeable, thinking, and innovative India is a progressive and harmonious India! It’s time to create a reading culture. India has seen the green revolution, India has seen the white revolution, and now is the time for a knowledge revolution!</p>
<pre>&nbsp;</pre>
<h4><span style={{'color': '#ff9900'}}>Why RIC/Read something new, diverse &amp; positive every day for 10 mins?</span></h4>
<ul>
<li><em>All successful people in the world are readers and independent thinkers&nbsp;</em></li>
<li><em>Reading ignites imagination and awareness</em></li>
<li><em>Your chance to experience the transformational “Read &gt; Think &gt; Act” process</em></li>
<li><em>Mind that opens to a new idea never returns to its original size (Albert Einstein)</em></li>
<li style={{'color': '#252525'}}><em>A global platform to celebrate learning, knowledge, ideas, and solutions</em></li>
<li style={{'color': '#252525'}}><em>Today you are a reader; tomorrow you will be a leader (Margret Fuller); today, you are a continuous learner; tomorrow, you will be a continuous gainer.</em></li>
<li style={{'color': '#252525'}}><em>Chance to win exciting prizes i.e. Laptops, Tablets &amp; </em><em>Certificates for all&nbsp;</em></li>
</ul>
<h6>&nbsp;</h6>
<h4><span style={{'color': '#ff9900'}}>Participation process for #ReadIndiaCelebration2023 (International):</span></h4>
<p>Students and citizens can participate in 3 simple steps (Register, Read, and Submit).</p>
<ol>
<li style={{'list-style-type': 'none'}}>
<ol>
<li><strong>Register – </strong>Visit <a href="http://www.readindia.info">www.readindia.info</a><strong>, </strong>click on the “Login/Sign Up” tab (top right corner), and follow the below steps
<ol>
<li><strong>Students:</strong> Scroll down, Click on “Sign Up Here” – If you are a Student</li>
<li><strong>Citizens:</strong> Scroll down, Click on “Sign Up Here” – If you are a Citizen</li>
<li>After sign-up with 4 fields (i.e. Full Name, Email Address<strong>,</strong> Mobile Number &amp; Password) is done, <strong>use your Email Address &amp; Password to log in.</strong> Post-login, fill in the details in the “My Profile” section to complete the registration process. * The registration fee is voluntary and <span style={{'text-decoration': 'underline'}}>not</span> mandatory for participation. <em>F</em>or any queries/issues, please write to us at readindiacelebration2023@gmail.com or call us at +91 9441456061.</li>
</ol>
</li>
<li><strong>Read</strong> – Read any non-academic book, story, self, or situation, or entity in existence (e.g. Earth, Water, Fire, Air, Space, River, Sea, Mountain, etc.), or research on a topic (refer to the proposed list of 25 themes and topics below). However, students and citizens are free to choose to read any non-academic book, story, article, situation, an entity in existence, or research topic (social topics only) of their personal choice that may not be on the proposed list for RIC2023. Note: RIC will share the link to download only the READ, THINK, ACT eBook…</li>
<li><strong>Submit</strong> – <strong>2 files</strong> (1 Document &amp; 1 Video from the Upload Files section in the Students &amp; Citizens Dashboard; both files are mandatory)<strong>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</strong></li>
</ol>
</li>
</ol>
<ol>
<li style={{'list-style-type': 'none'}}>
<ol>
<li style={{'list-style-type': 'none'}}>
<ol>
<li style={{'text-align': 'left'}}><strong>Doc file: </strong>A4 size, 1 page only (PDF or MS Word doc)</li>
</ol>
</li>
</ol>
</li>
</ol>
<ul>
<li style={{'list-style-type': 'none'}}>
<ul>
<li style={{'list-style-type': 'none'}}>
<ul>
<li style={{'list-style-type': 'none'}}>
<ul>
<li style={{'list-style-type': 'none'}}>
<ul>
<li><strong>Page 1 (1st half):</strong> Summarise the key message (positive), share learning from the book/story/self/entity in existence/event in history you read, or present views on the research topic/theme (Minimum 3 Pros &amp; Cons + conclusion) in your own words (*Note: It’s either book, story, self, situation, personal experience or presenting views on a topic but not both)</li>
<li><strong>Page 1 (2nd half):</strong> Describe in brief any school/college/social/community/regional/global problem (e.g. Bullying, addiction to gadgets, social media, gaming, lack of physical exercise, air pollution, river pollution, drying of lakes, lack of playgrounds, corruption, media manipulation, disruption due to street protests, etc.) of importance to you and propose a creative solution</li>
</ul>
</li>
</ul>
</li>
</ul>
</li>
</ul>
</li>
</ul>
<ol>
<li style={{'list-style-type': 'none'}}>
<ol>
<li style={{'list-style-type': 'none'}}>
<ol>
<li style={{'text-align': 'left'}}><strong>Video file: </strong>Make a 2-minute video (Camera at face level, cell phone in a horizontal position, looking into the camera, speak with the face clearly visible &amp; voice audible, should <strong>not</strong> be a readout!) about the key message or learning from the book or story or entity in existence or research topic or on the problem &amp; solution starting with a self-introduction)</li>
</ol>
</li>
</ol>
</li>
</ol>
<p>*Submit both doc &amp; video files at the same time, if possible else one after the other i.e., select 2 files and click on save &amp; continue from the student’s / citizens dashboard (Upload Files Section). To check the files that got submitted, go to the Upload Files section, and check if you can view &amp; download them, no email will be sent as confirmation for submission.</p>
<p><strong>Student Categories:</strong> Category 1: 3-5 grade, Category 2: 6-9 grade, Category 3: 10-12 grade, Category 4: Undergrad &amp; above; Citizens – No age limit</p>
<p><strong>*</strong>For students &amp; citizens in India:<strong>&nbsp;Hindi, Telugu, Marathi &amp; English language </strong>submissions are allowed.&nbsp;</p>
<p><strong>Note:</strong>&nbsp;Students of all nationalities from around the world can participate. International submissions are allowed only in the<strong> English language.</strong></p>
<h5><a href="https://readindia.info/wp-content/uploads/2022/07/READ.-THINK.-ACT.-2022-Edition-v2.1.pdf">Click here to download the READ. THINK. ACT. eBook and experience the unteachable!</a></h5>
<h4>&nbsp;</h4>
<h4><span style={{'color': '#ff9900'}}>Timelines for #RIC2023:</span></h4>
<ol>
<li style={{'list-style-type': 'none'}}>
<ol>
<li><em>Last Date For The Online Registrations – <strong>Aug 15th&nbsp;</strong></em></li>
<li><em>Last Date For The Online Video &amp; Document Submissions – <b>Sep 5th&nbsp;</b></em></li>
<li><em>Round #1 Evaluations – <strong>Sep &amp; Oct</strong></em></li>
<li><em>Round #2 Screening – <strong>Nov (Dates TBC in Nov’2023)</strong></em></li>
<li><em>Grand Finale – <strong>Dec (Dates TBC in Nov’2023)</strong></em></li>
</ol>
</li>
</ol>
<p><em>*Please keep in mind that the above timelines are subject to chang</em>e depending on the volume of participation and RIC discretion!</p>
<h4><span style={{'color': '#ff9900'}}>Awards and Recognitions:&nbsp;</span></h4>
<p><strong>Participants:</strong></p>
<ol>
<li>Category 1 | Grade 3rd to 5th – 1st Prize: Laptop; 2nd Prize: Tablet</li>
<li>Category 2 | Grade 6th to 9th – 1st Prize: Laptop; 2nd Prize: Tablet</li>
<li>Category 3 | 10th to 12th – 1st Prize: Laptop; 2nd Prize: Tablet</li>
<li>Category 4 | Undergrad &amp; Grad – 1st Prize: Laptop; 2nd Prize: Tablet</li>
<li>Category 5 | Citizens (working &amp; retired) – 1st Prize: Laptop; 2nd Prize: Tablet</li>
</ol>
<p>*Certificates to all participants who make submissions.</p>
<p><strong>Schools, Colleges, Teachers and Mentors:</strong></p>
<ol>
<li style={{'color': '#252525'}}>The school with the maximum participation will be awarded a trophy</li>
<li style={{'color': '#252525'}}>A college with maximum participation will be awarded a trophy</li>
<li style={{'color': '#252525'}}>A teacher or librarian who inspires maximum participation in the school category will be rewarded</li>
<li style={{'color': '#252525'}}>A professor, lecturer, or mentor who inspires maximum participation in colleges (category 4) will be rewarded</li>
<li style={{'color': '#252525'}}>Certificates to teachers, lecturers, and librarians with inspiring tales</li>
</ol>
<h4><em><span style={{'color': '#ff9900'}}><strong>Proposed Themes / Topics:</strong></span></em></h4>
<ol>
<li><em>The Importance of the Invention of “Zero/Shunya” in Technological Evolution and the Role of “Shunyata/Meditation” in Human Evolution!</em></li>
<li><em>The Road Ahead for Humanity – Knowledge-based Culture Vs Commerce-based Culture?</em></li>
<li><em>Leadership Styles – Values or Valuables; Greed or Honesty; Service or Grandeur</em></li>
<li><em>Entrepreneurs Vs Job Seekers (Risk Vs Comfort?)</em></li>
<li><em>Creativity Vs Knowledge Vs Logic</em></li>
<li><em>Top priorities for the state – Education, Health Care, Nature Care &amp; Employment Creation?</em></li>
<li><em>Beliefs-Based Society Vs Independent Thinking Society – Merits &amp; Demerits!!</em></li>
<li><em>Technology for Evolution (choice) Vs Technology for Devolution (addiction &amp; abuse)?</em></li>
<li><em>Between Use and Abuse of Natural Resources – How to Strike a Balance?</em></li>
<li><em>People in Policy – Courage (&amp; the price to be paid) Vs Fear (&amp; consequences)</em></li>
<li><em>A Progressive Society – Is it People or State-driven?</em></li>
<li><em>A cultural shift to nurturing creativity – From being part of the problem (/silent) to being part of the solution (think &amp; experiment solutions!)</em></li>
<li><em>Leaders – How to draw the line between need &amp; greed?</em></li>
<li><em>Is there a need to introduce a global citizen element in the school education system while keeping the local flavours?</em></li>
<li><em>Indian &amp; Global Education Systems</em></li>
<li><em>World History and History of Religion</em></li>
<li><em>Religion, Race, Politics, Conquest &amp; Loot</em></li>
<li><em>The Importance of Self-Awareness: What Constitutes the Self/Human Being? (Air, Water, Earth, Fire, and Space elements? Is breath or energy tying up all the 5 essential elements?)</em></li>
<li><em>Fixing accountability on countries spreading pandemics/global unrest</em></li>
<li><em>The need for stronger &amp; accountable global bodies to deal with 21st-century pandemics/global challenges</em></li>
<li><em>Is there a need to add a course/subject on Emotional Intelligence as part of the curriculum for high school students globally?</em></li>
<li><em>A student’s guide to preparing for the Unknowns &amp; Uncertainties in life!</em></li>
<li><em>Is Knowledge the only thread that can keep the world united &amp; harmonious in diversity?</em></li>
<li><em>Why do Ignorance &amp; Intelligence go together in the “I / Self”? and how does one know from where the self is operating often (Intelligence or Ignorance?)?</em></li>
<li><em><em>Are Ignorant Leaders the greatest threat to our planet?</em></em></li>
</ol>
<h5 style={{'text-align': 'left'}}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</h5>
<h5><span style={{'color': '#000080'}}><strong>READ INDIA CELEBRATION AND INDIA POSITIVE CITIZEN COLLABORATE FOR A CLEANER, GREENER AND HEALTHIER INDIA:</strong></span></h5>
<p style={{'text-align': 'left', 'padding-left': '160px'}}>
<img decoding="async" alt="" width="300" height="137" data-srcset="https://readindia.info/wp-content/uploads/2023/05/indian-flag-38509-e1683446468596-300x137.png 300w, https://readindia.info/wp-content/uploads/2023/05/indian-flag-38509-e1683446468596-1024x468.png 1024w, https://readindia.info/wp-content/uploads/2023/05/indian-flag-38509-e1683446468596-768x351.png 768w, https://readindia.info/wp-content/uploads/2023/05/indian-flag-38509-e1683446468596.png 1200w" data-src="https://readindia.info/wp-content/uploads/2023/05/indian-flag-38509-e1683446468596-300x137.png" data-sizes="(max-width: 300px) 100vw, 300px" className="size-medium wp-image-5921 alignleft lazyloaded" src="https://readindia.info/wp-content/uploads/2023/05/indian-flag-38509-e1683446468596-300x137.png" sizes="(max-width: 300px) 100vw, 300px" srcset="https://readindia.info/wp-content/uploads/2023/05/indian-flag-38509-e1683446468596-300x137.png 300w, https://readindia.info/wp-content/uploads/2023/05/indian-flag-38509-e1683446468596-1024x468.png 1024w, https://readindia.info/wp-content/uploads/2023/05/indian-flag-38509-e1683446468596-768x351.png 768w, https://readindia.info/wp-content/uploads/2023/05/indian-flag-38509-e1683446468596.png 1200w"/>
<noscript>
<img decoding="async" className="size-medium wp-image-5921 alignleft" src="https://readindia.info/wp-content/uploads/2023/05/indian-flag-38509-e1683446468596-300x137.png" alt="" width="300" height="137" srcset="https://readindia.info/wp-content/uploads/2023/05/indian-flag-38509-e1683446468596-300x137.png 300w, https://readindia.info/wp-content/uploads/2023/05/indian-flag-38509-e1683446468596-1024x468.png 1024w, https://readindia.info/wp-content/uploads/2023/05/indian-flag-38509-e1683446468596-768x351.png 768w, https://readindia.info/wp-content/uploads/2023/05/indian-flag-38509-e1683446468596.png 1200w" sizes="(max-width: 300px) 100vw, 300px" />
</noscript>
<a href="https://www.indiapositivecitizen.com/shop">
<img decoding="async" alt="" width="300" height="300" data-srcset="https://readindia.info/wp-content/uploads/2023/05/RIC_IndiaPostiiveCitizen_Flyer-300x300.jpeg 300w, https://readindia.info/wp-content/uploads/2023/05/RIC_IndiaPostiiveCitizen_Flyer-1024x1024.jpeg 1024w, https://readindia.info/wp-content/uploads/2023/05/RIC_IndiaPostiiveCitizen_Flyer-150x150.jpeg 150w, https://readindia.info/wp-content/uploads/2023/05/RIC_IndiaPostiiveCitizen_Flyer-768x768.jpeg 768w, https://readindia.info/wp-content/uploads/2023/05/RIC_IndiaPostiiveCitizen_Flyer-1536x1536.jpeg 1536w, https://readindia.info/wp-content/uploads/2023/05/RIC_IndiaPostiiveCitizen_Flyer.jpeg 1600w" data-src="https://readindia.info/wp-content/uploads/2023/05/RIC_IndiaPostiiveCitizen_Flyer-300x300.jpeg" data-sizes="(max-width: 300px) 100vw, 300px" className="alignnone size-medium wp-image-5945 lazyloaded" src="https://readindia.info/wp-content/uploads/2023/05/RIC_IndiaPostiiveCitizen_Flyer-300x300.jpeg" sizes="(max-width: 300px) 100vw, 300px" srcset="https://readindia.info/wp-content/uploads/2023/05/RIC_IndiaPostiiveCitizen_Flyer-300x300.jpeg 300w, https://readindia.info/wp-content/uploads/2023/05/RIC_IndiaPostiiveCitizen_Flyer-1024x1024.jpeg 1024w, https://readindia.info/wp-content/uploads/2023/05/RIC_IndiaPostiiveCitizen_Flyer-150x150.jpeg 150w, https://readindia.info/wp-content/uploads/2023/05/RIC_IndiaPostiiveCitizen_Flyer-768x768.jpeg 768w, https://readindia.info/wp-content/uploads/2023/05/RIC_IndiaPostiiveCitizen_Flyer-1536x1536.jpeg 1536w, https://readindia.info/wp-content/uploads/2023/05/RIC_IndiaPostiiveCitizen_Flyer.jpeg 1600w"/>
<noscript>
    <img decoding="async" className="alignnone size-medium wp-image-5945" src="https://readindia.info/wp-content/uploads/2023/05/RIC_IndiaPostiiveCitizen_Flyer-300x300.jpeg" alt="" width="300" height="300" srcset="https://readindia.info/wp-content/uploads/2023/05/RIC_IndiaPostiiveCitizen_Flyer-300x300.jpeg 300w, https://readindia.info/wp-content/uploads/2023/05/RIC_IndiaPostiiveCitizen_Flyer-1024x1024.jpeg 1024w, https://readindia.info/wp-content/uploads/2023/05/RIC_IndiaPostiiveCitizen_Flyer-150x150.jpeg 150w, https://readindia.info/wp-content/uploads/2023/05/RIC_IndiaPostiiveCitizen_Flyer-768x768.jpeg 768w, https://readindia.info/wp-content/uploads/2023/05/RIC_IndiaPostiiveCitizen_Flyer-1536x1536.jpeg 1536w, https://readindia.info/wp-content/uploads/2023/05/RIC_IndiaPostiiveCitizen_Flyer.jpeg 1600w" sizes="(max-width: 300px) 100vw, 300px" /></noscript></a></p>
<p><span style={{'color': '#000000'}}><strong>Read</strong> &gt; Environment, <strong>Think</strong> &gt; Green, <strong>Act</strong> &gt; Plant = A RESPONSIBLE AND WISER INDIA MADE POSSIBLE WITH YOUR PARTICIPATION!</span></p>
<p><span style={{'color': '#000000'}}><strong>USE CODE: READINDIA</strong> AT CHECKOUT IN <a style={{'color': '#000000'}} href="https://www.indiapositivecitizen.com/shop">I<span style={{'color': '#0000ff'}}>NDIA POSITIVE CITIZEN ONLINE STORE</span></a> TO GET A PACK OF MORINGA SEEDS AND INSTRUCTIONS TO PLANT THEM. </span></p>
<p><span style={{'color': '#000000'}}><strong>USE CODE: READINDIA</strong> AT CHECKOUT IN <a href="https://www.hindueshop.com/">
    <img decoding="async" alt="" width="165" height="31" 
    data-src="https://readindia.info/wp-content/uploads/2023/05/HES-logo_blackbg.png" 
    className="alignnone size-full wp-image-5954 lazyloaded" 
    src="https://readindia.info/wp-content/uploads/2023/05/HES-logo_blackbg.png"/>
        <noscript>
            <img decoding="async" className="alignnone size-full wp-image-5954" 
            src="https://readindia.info/wp-content/uploads/2023/05/HES-logo_blackbg.png" 
            alt="" width="165" height="31"/>
            </noscript></a> <a href="https://www.hindueshop.com/">ONLINE STORE</a> TO GET DISCOUNT ON PURCHASE OF BOOKS</span><span style={{'color': '#000000'}}>.&nbsp;</span></p>
<h4 style={{'text-align': 'center'}}>&nbsp;</h4>
<h4><strong>#ReadIndiaCelebration2023(International)–Connecting a BILLION READERS!</strong></h4>
<p>It is&nbsp;to make participants experience the process for success called”</p>
<ol>
<li style={{'list-style-type': 'none'}}>
<ol>
<li><strong>Read </strong>emphasises 3 R’s
<ul>
<li>Reading Non-Academic Books</li>
<li>Reading Self</li>
<li>Reading Situations</li>
</ul>
</li>
<li><strong>Think </strong>focuses on 3 things that can be done to get better</li>
<li><strong>Act </strong>emphasises doing those 3 things daily with commitment</li>
</ol>
</li>
</ol>
<p><em><strong>*</strong>Knowledge, intelligence, and wisdom are personal journeys. RIC feels self-help is the best help! This initiative is for those who want to help themselves, those who like to do it themselves, and those who want to experiment and experience the READ, THINK, ACT process. This is for people who want to celebrate knowledge, knowing, and independent thinking. This initiative is <span style={{'text-decoration': 'underline'}}>not</span> for competitors or people who want to win; for people looking for help; or for those who want to do scientific research or presentations. <strong>Competitors, please, 
    <span style={{'text-decoratio': 'underline'}}>stay away</span>!</strong></em></p>
<p>&nbsp;</p>
</div>
    </>
    
   </>
      )}
      </>
  )
}

export default International