import React, { useEffect, useRef, useState }  from 'react';
import Carousel from 'react-bootstrap/Carousel';
// import { Carousel } from 'react-responsive-carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'font-awesome/css/font-awesome.min.css';
import playbtn from '../Components/assets/img/play-btn.svg';
import { CSSTransition } from 'react-transition-group';
import { Link } from "react-router-dom";
import { Fade, Slide, Bounce, Zoom } from "react-awesome-reveal";
// import YouTube from 'react-youtube';
import YouTube, { Options, OnReadyEvent } from 'react-youtube';
import {getHomeRead, getHomepage, getBannerPages} from '../store/home';
import { useDispatch, useSelector } from 'react-redux';
import BeatLoader from "react-spinners/BeatLoader";
import googleplay from '../Components/assets/img/google-play.png';


const Home = () => {

  const disablePreviousButton = false;
  const disableNextButton = false;

  const renderArrowPrev = (onClickHandler, hasPrev, label) => {
    return hasPrev && !disablePreviousButton ? (
      <button type="button" onClick={onClickHandler} title={label}>
        Previous
      </button>
    ) : null;
  };

  const renderArrowNext = (onClickHandler, hasNext, label) => {
    return hasNext && !disableNextButton ? (
      <button type="button" onClick={onClickHandler} title={label}>
        Next
      </button>
    ) : null;
  };


  const play = playbtn;


//READ THINK ACT REDUCER START
const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHomeRead())},
    [])
    const st = useSelector(state => state.home);
    const state = useSelector(state => state.home)
    const homerec = useSelector(state => state.home.home_array)
    const homeread =state.HomeRead;
   

   //section4 content management start
   // Get the summary value
const summary = homeread?.section4?.[0]?.[0]?.desc?.[0]?.summary;

// Create a temporary element
const tempElement = document.createElement('div');
tempElement.innerHTML = summary;

// Extract the text content without HTML tags
const textWithoutTags = tempElement.innerText;

// Use the textWithoutTags in your code
console.log(textWithoutTags); // Display the summary without HTML tags
   //section4 content management end

//READ THINK ACT REDUCER END



/*Celebrating, Readership & Leadership start */

const homeLearning = homeread;

// const totalImages = homeLearning?.section3?.pagesliders.length;
// const maxSlidesToShow = totalImages <= 4 ? totalImages : 4;

const settings = {
  dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 1,
    responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
  autoplay: true,
  autoplaySpeed: 2000,
};


/*Celebrating, Readership & Leadership end */

/*Latest News start */
// Play button start
const onPlayerReady = (event) => {
  // access to player in all event handlers via event.target
  event.target.pauseVideo();
};

const [selectedVideo, setSelectedVideo] = useState(null);

const handleTitleClick = (videoId) => {
  setSelectedVideo(videoId);
};

const handleCloseModal = () => {
  setSelectedVideo(null);
};

const opts = {
  height: '220',
  width: '330',
  playerVars: {
    // autoplay: 1,
  },
};


const settings2 = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
  autoplay: true,
  autoplaySpeed: 2000,
};
// Play button end


//Banner pages start


  useEffect(() => {
    dispatch(getBannerPages());
  },[]);
  const stat = useSelector(state => state.home);
  const bannerpage = useSelector(state => state.home.BannerPages);
  console.log('bannerpag', bannerpage.status);
//banner pages end






/*Latest News start */
const [scrollPosition, setScrollPosition] = useState(0);

useEffect(() => {
  const handleScroll = () => {
    const currentPosition = window.scrollY || document.documentElement.scrollTop;
    setScrollPosition(currentPosition);
  };

  window.addEventListener('scroll', handleScroll);

  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);

useEffect(() => {
  const windowHeight = window.innerHeight;
  const documentHeight = document.documentElement.scrollHeight;

 
}, [scrollPosition]);






return (


  <>
   {st.loaderStatus ? (
        <div className="loader-container">
        <BeatLoader loading={st.loaderStatus} size={50} color="#36d7b7" />
      </div>
      ) : (
  <>

  <div className="slide-wrap inner1">
  
    <div className="container " >
      <div id="home-carousel" className="owl-carousel owl-theme" style={{'paddingBottom':'25px'}}>
        {/* <Carousel  responsive={responsive} interval={2000} pauseOnHover={false} pause={false}> */}
         <Carousel
            interval={5000}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            renderArrowPrev={renderArrowPrev}
            renderArrowNext={renderArrowNext}
          >
          {homeread?.banner?.banners.map((banner) => (
<Carousel.Item key={banner.id}>
  <div className="flex-group">
  <div className="flex-item">
  <span className="slide-head wow fadeInUp banner-desc" dangerouslySetInnerHTML={{ __html: banner.img_desc }}></span>
  {banner.img_link ? (
    <Link to={banner.img_link} className="btn btn-rounded btn-md btn-success"><i class="fa fa-plus" aria-hidden="true"></i>
      &nbsp;{banner.img_alt}
    </Link>
  ) : null}
  <span> 
    <Link to="https://play.google.com/store/apps/details?id=com.readindiacelebration&hl=en_US">
    <img src={googleplay} alt="" style={{'height': 'auto', 'width': '135px', 'marginLeft':'2%'}}/>
    </Link>
  </span>
</div>

    <div className="flex-item bnrimage">
      {/* <Zoom> */}
        <img
          src={homeread?.banner?.banner_path + banner.name}
          style={{'height':'300px'}}
          className="img-fluid wow zoomIn"
          alt={banner.img_alt}
          title={banner.img_alt}
        />
      {/* </Zoom> */}
    </div>
  </div>
</Carousel.Item>
))}
        </Carousel>
      </div>
    </div>
  </div>

  <>
   {/* <!-- about  --> */}
  <section className="section-wrapper about-company">
    <div className="container">
     <div className="flex-close">
   
        <>
        <div className="flexItem">
          <div className="green-bg btn-rounded text-uppercase wow fadeInUp">Read, Think, Act</div>
          <h1 className="head1 wow fadeInUp">{homeread?.section1?.name}</h1>
        </div>
        <div className="flexItem flexGroup">
          <div className="cardgroup">
            <div className="cardBx">
            <Slide direction="down">
              <img src={homeread?.imagepath + homeread?.section1?.desc[0]?.image_arr}
              className="img-fluid wow fadeInUp fadeInDown" data-wow-delay="0.2" 
              alt={homeread?.section1?.desc['0']?.image_alt} title="Read India"/>
              </Slide>
              <h3>{homeread?.section1?.desc['0']?.title}</h3>
              <p>{homeread?.section1?.desc['0']?.summary}</p>
            </div>
            <div className="cardBx">
              <Slide>
              <img src={homeread?.imagepath + homeread?.section1?.desc[2]?.image_arr}
              className="img-fluid wow fadeInLeft" data-wow-delay="0.4" 
              alt={homeread?.section1?.desc['2']?.image_alt} title="Read India"/>
              </Slide>
              <h3>{homeread?.section1?.desc['2']?.title}</h3>
              <p>{homeread?.section1?.desc['2']?.summary}</p>
            </div>
          </div>
          <div className="cardgroup">
            <div className="cardBx">
              <Zoom>
              <img src={homeread?.imagepath + homeread?.section1?.desc[1]?.image_arr}
              className="img-fluid wow zoomIn" data-wow-delay="0.6" 
              alt={homeread?.section1?.desc['1']?.image_alt} title="Read India"/>
              </Zoom>
              <h3>{homeread?.section1?.desc['1']?.title}</h3>
              <p>{homeread?.section1?.desc['1']?.summary}</p>
            </div>
          </div>
        </div>

         </>
   
      </div>
   

      {/* <!-- thinking --> */}
      <div className="think-wrap pt-5 pb-4 text-center">
        <h1 className="head1 text-center text-uppercase spacing">{homeread?.section2?.name}</h1>
      <p>{homeread?.section2?.desc['0']?.summary}</p>
        <img src={homeread?.imagepath + homeread?.section2?.desc[0]?.image_arr} 
        className="img-fluid" alt={homeread?.section2?.desc['0']?.image_alt} title="Is Your Thinking"/>
        <h3 className="head3 spacing secondary-color text-uppercase">{homeread?.section2?.desc['0']?.tag}</h3>
      </div>
      {/* <!-- end think --> */}
      
    </div>
  </section>
  {/* <!-- end -->*/}
  </> 


  <>
  {/* <!-- clebrating --> */}
  <section className="section-wrapper grey-bg">
    <div className="container">
      <center>
      <img width="120" alt="book" title="" 
      data-src={homeread?.imagepath + homeread?.section3?.desc[0]?.image_arr} className="img-fluid lazyloaded" 
      src={homeread?.imagepath + homeread?.section3?.desc[0]?.image_arr}/>
      </center>
      <h1 className="head1 text-center mb-5">{homeread?.section3?.name}</h1>
      <div className="owl-carousel owl-theme" id="tips">
      <Slider {...settings}>
{homeLearning?.section3?.pagesliders.map((page, pageIndex) => (
  <div className="p-2" key={pageIndex}>
    <div className="card tips-item text-center">
      <div className="card-body">
        <div className="tips-item-img">
          <img
            src={homeread?.imagepath + page[0].image_arr}
            className="img-fluid"
            alt={page[0].title}
            title={page[0].title}
          />
        </div>
        <h3 className="card-title">{page[0].title}</h3>
        <p className="card-text">{page[0].summary}</p>
      </div>
    </div>
  </div>
))}
{homeLearning?.section3?.pagesliders.map((page, pageIndex) => (
  <div className="p-2" key={pageIndex}>
    <div className="card tips-item text-center">
      <div className="card-body">
        <div className="tips-item-img">
          <img
            src={homeread?.imagepath + page[0].image_arr}
            className="img-fluid"
            alt={page[0].title}
            title={page[0].title}
          />
        </div>
        <h3 className="card-title">{page[0].title}</h3>
        <p className="card-text">{page[0].summary}</p>
      </div>
    </div>
  </div>
))}

</Slider>

      </div>
      <h3 className="head3 spacing secondary-color text-center text-uppercase">{homeread?.section3?.desc['0']?.tag}</h3>
    </div>
  </section>
  {/* <!-- end --> */}
  </>


<>
{/* <!-- ric start --> */}
  <section className="section-wrapper mt-5 secondary-shape">
    <div className="section-grid">
      <div className="container">
      <div className="row">
        <div className="col-md-6 col-lg-6 col-sm-12">
          <div className="set-graphic">
            <Slide>
            <img src={homeread?.imagepath + homeread?.section4?.[0]?.[0]?.desc?.[0]?.image_arr}
            className="img-fluid wow fadeInLeft"
             alt="RIC 2021 (International)" 
             title="RIC 2021 (International)"/>
            </Slide>
          </div>
        </div>
        <div className="col-lg-6 col-lg-6 col-sm-12">
          <div className="wrapper-content mt-5">
            <Slide direction="up">
            <h3 className="head3 mb-4 wow fadeInUp">
              { homeread?.section4?.[0]?.[0]?.desc?.[0]?.title}
            </h3>
            <p className="mb-4 wow fadeInUp" dangerouslySetInnerHTML={{ __html: summary }}>
           
            </p>
            <a href={ homeread?.section4?.[0]?.[0]?.desc?.[0]?.btn_link} className="btn btn-rounded btn-success btn-lg wow fadeInUp">
              <i className="fa fa-plus" aria-hidden="true"></i> Read More</a>
              </Slide>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div className="section-grid pt-5 pb-5">
      <div className="container">
      <div className="row">
        <div className="col-lg-6 col-lg-6 col-sm-12">
          <div className="wrapper-content mt-5">
          <Slide direction="up">
            <h3 className="head3 mb-4 wow fadeInUp">
              {homeread?.section4?.[0]?.[0]?.desc?.[1]?.title}
            </h3>
           
            <p className="mb-4 wow fadeInUp">
             {homeread?.section4?.[0]?.[0]?.desc?.[1]?.summary}
              </p>
           
            <a href={homeread?.section4?.[0]?.[0]?.desc?.[1]?.btn_link} className="btn btn-rounded btn-success btn-lg wow fadeInUp">
              <i className="fa fa-play" aria-hidden="true"></i> Read More</a>
            </Slide>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-sm-12">
          <div className="set-graphic">
          <Slide direction="right">
            <img src={homeread?.imagepath + homeread?.section4?.[0]?.[0]?.desc?.[1]?.image_arr} 
            className="img-fluid wow fadeInRight" alt="RIC 2021 (International)" title="RIC 2021 (International)"/>
            </Slide>
          </div>
        </div>
      </div>
    </div>
    </div>
  </section>
  {/* <!-- end start --> */}



  {/* <!-- join us --> */}
  <section className="section-wrapper pt-0 joinus text-center">
    <div className="container">
     <Slide direction="up" >
      <h3 className="head3 text-uppercase wow fadeInUp">{homeread?.section5?.name}</h3>
      <h4 className="text-uppercase head4 wow fadeInUp" dangerouslySetInnerHTML={{ __html: homeread?.section5?.desc['0']?.summary }}></h4>
       <a href={homeread?.section5?.desc['0']?.btn_link} className="btn btn-lg btn-rounded btn-success wow fadeInUp">
      <i className="fa fa-plus" aria-hidden="true"></i>&nbsp; Know More</a>
     </Slide>
    </div>
  </section>
  {/* <!-- end --> */}
</>


<>
{/* <!-- Architects of Transformation --> */}
  <section className="section-wrapper impact">
    <div className="container">
      <div className="row">
        <div className="col-md-5 col-lg-5 col-sm-12">
          <div className="wrapper-content flex-center">
            <div className="head-wrap">
            <Slide direction="up">
              <h1 className="head1 wow fadeInUp">{homeread?.section6?.name}</h1>
            <p className="mb-4 wow fadeInUp" dangerouslySetInnerHTML={{ __html: homeread?.section6?.desc['0']?.summary }}></p>
            {/* <a href="#link" className="btn btn-lg btn-rounded btn-success">View All</a> */}
            </Slide>
            </div>
          </div>
        </div>
        <div className="col-md-7 col-lg-7 col-sm-12">
          <div className="videos-group row">
            {/* <!-- videos --> */}
            <div className="col-md-6">
            {/* <Slide direction="up"> */}
              <div className="video-cover" >
            <YouTube videoId={homeread?.section6?.desc['0']?.yl_two}  opts={opts} onReady={onPlayerReady}  />
              {/* <!-- video --> */}
              </div>
              {/* </Slide> */}
              {/* <Slide direction="up"> */}
              <div className="video-list mt30"> 
              <div className="video-cover" >
              <YouTube videoId={homeread?.section6?.desc['0']?.yl_three} opts={opts} onReady={onPlayerReady}  />
              
              {/* <!-- modal --> */}
            </div>
            </div>
              {/* </Slide> */}
            </div>
            {/* <!-- videos --> */}
            <div className="col-md-6 mt-5" >
            {/* <Slide direction="up"> */}
            <div className="video-cover">
              <YouTube videoId={homeread?.section6?.desc['0']?.yl_one} opts={opts} onReady={onPlayerReady}  />
              
              {/* <!-- modal --> */}
             
              </div>
              {/* </Slide> */}
              {/* <!-- video --> */}
              {/* <Slide direction="up"> */}
              <div className="video-list mt30"> 
              <div className="video-cover" >
              <YouTube videoId={homeread?.section6?.desc['0']?.yl_four} opts={opts} onReady={onPlayerReady}  />
              
              {/* <!-- modal --> */}
              
              </div>
              </div>
              {/* </Slide> */}
              {/* <!-- end --> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* <!-- Architects of Transformation --> */}
</>



{/* <!-- latest news --> */}
  <>
<section className="section-wrapper news-wrap pt-0">
<div className="container">
  <h1 className="text-center head1">Latest News</h1>
  <div className="latestdesg" id="news-carousel" style={{ paddingTop: '2%' }}>
    <Slider {...settings2}>
      {homeread?.latestnews?.map((newsItem) => (
        <div key={newsItem.id} className="">
          <div class="news-card head5">
          <div className="news-item newsdesg">
            <YouTube videoId={newsItem.youtube_link} opts={opts} onReady={onPlayerReady} />
          </div>
          <a
            href={`https://www.youtube.com/watch?v=${newsItem.youtube_link}`}
            target="_blank"
            onClick={() => handleTitleClick(newsItem.youtube_link)}
          >
            <h5>{newsItem.title}</h5>
          </a>
          </div>
        </div>
      ))}
      </Slider>
  </div>
</div>
</section>
  </>
  </>
  )}
  </>
);
}


export default Home;
