import React, { useEffect, useRef, useState }  from 'react';
import TeamSVG from '../Components/assets/img/team.svg';
import { Fade, Slide, Bounce, Zoom } from "react-awesome-reveal";
import { useDispatch, useSelector } from 'react-redux';
import {getTeam} from '../store/home';
import { BeatLoader } from 'react-spinners';



const Team = () => {


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTeam());
  },[]);
  const st = useSelector(state => state.home);
    const team = useSelector(state => state.home.Team);
 console.log('pramodini',team);
    

    // const teamMember = team;

    const Paragraph = ({ member }) => {
      const [expanded, setExpanded] = useState(false);
    
      const handleClick = () => {
        setExpanded(!expanded);
      };
    
      const truncatedText = expanded ? member.summary : member.summary.slice(0, 20);
      const buttonText = expanded ? 'Know Less' : 'Know More';

     
      return (
        
        <div>
          <p className="paradesg" dangerouslySetInnerHTML={{ __html: truncatedText}}></p>
          {member.summary.length > 20 && (
            <button className="teambtn" onClick={handleClick}>{buttonText}</button>
          )}
        </div>
      );
    };
  
    return (
      <>
    {st.loaderStatus ? (
        <div className="loader-container">
        <BeatLoader loading={st.loaderStatus} size={50} color="#36d7b7" />
      </div>
      ) : (
<div>

 {/* <!-- carousel --> */}
 <div className="slide-wrap inner">
    <div className="container">
      <div className="flex-grid">
      <Slide direction="up">
        <div className="page-heading">
          <h3>Team</h3>
          <div className="content wow fadeInUp" dangerouslySetInnerHTML={{ __html:  team?.banner?.['0']?.img_desc}}>
          </div>
        </div>
        </Slide>
        <Zoom>
        <div className="page-img wow fadeInUp">
        {/* {`${team?.banner_path}${ team?.banner?.['0']?.name}`} */}
          <img src={team?.banner_path + team?.banner?.['0']?.name} 
          className="img-fluid" 
          alt={team?.banner?.['0']?.img_alt} title="TeamSVG"/>
        </div>
        </Zoom>
      </div>
    </div>
    </div>
    {/* <!-- end --> */}
      <>

        <div className="section-wrapper">
          <div className="container">
            <h3 className="head3 mt-0 text-center wow fadeInUp animated" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>Team</h3>
            <div className="content wow fadeInUp text-center animated" style={{ visibility: 'visible', animationName: 'fadeInUp' }} dangerouslySetInnerHTML={{ __html:  team?.description}}></div>
            <div className="team-wrap row mt-4 mb-5">
              {team?.teamlists?.map((member, pageIndex) => (
               
                <div className="col-md-3" key={pageIndex}>
                  <div className="profile wow fadeInUp animated" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
                    <div className="team-cover light-green">
                      <img
                        alt="name here"
                        title="name"
                        data-src={team?.imagepath + member.image}
                        className="img-fluid lazyloaded"
                        src={team?.imagepath + member.image}
                      />
                      <noscript>
                        <img src={team?.imagepath + member.image} className="img-fluid" alt="name here" title="name" />
                      </noscript>
                    </div>
                    <h4 className="team-name" style={{'fontSize': '13px'}} >{member.name}</h4>
                    <h5  className="teampara" >{member.designation}</h5>
                    <Paragraph member={member} />
                  </div>
                </div>
               
              ))}
            </div>
          </div>
        </div>
      </>
      </div>
       )}
       </>
    );
  };
  
  export default Team;