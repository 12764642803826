import React, { useState, useEffect, useRef  } from 'react';
import { FaArrowUp } from 'react-icons/fa';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getFooter, saveNews, getFooterLinks } from '../store/home';
import { useForm } from 'react-hook-form';
import facebook from "../Components/assets/img/facebook-icon.png";
import twitter from "../Components/assets/img/twitter-icon.png";
import instagram from "../Components/assets/img/instagram-icon.png";

const Footer = () => {


  //REDUCER START
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFooter());
    dispatch(getFooterLinks());
  }, []);

  const footer = useSelector(state => state.home.Footer);
  const header = useSelector(state => state.home.Header);
  const footerlink = useSelector(state => state.home.FooterLink);



  const st = useSelector(state => state.home);

  //REDUCER END


  const [year, setYear] = useState(new Date().getFullYear());


  useEffect(() => {
    const interval = setInterval(() => {
      setYear(new Date().getFullYear());
    }, 1000 * 60 * 60 * 24); // Update every day

    return () => {
      clearInterval(interval);
    };
  }, []);


  // scroll to top button start


  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const isVisible = scrollY > 100;
    setIsVisible(isVisible);
  };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  // scroll to top button end


  const [email, setEmail] = useState('');
//NEWS LATER START
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const formRef = useRef();

const { register, handleSubmit, reset, formState: { errors } } = useForm();


const onSubmit = (data) => {
  dispatch(saveNews(data));

  reset();

  setShowSuccessMessage(true);

  setTimeout(() => {
    setShowSuccessMessage(false);
   }, 7000);
  
};

const success_msg = st?.newslatter_submit;
console.log('succmsg', success_msg);


const subscribeMessage = () => {
        
          if(st?.newslatter_submit){
              return  <span className='register-msg-style'>{st?.newslatter_submit}</span>
          } 
        }


//NEWS LATER END

//Who are we start
// useEffect(() => {
//   dispatch(getFooterLinks());
// }, []);
// const footerlink = useSelector(state => state.home.FooterLink);
// console.log("footerlink", footerlink);
//Who are we end

  return (
    <div>
       <footer className="footer">
        <div className="container">
          <div className="row justify-content-center">
            {/* Fixed header */}
            <div className="col-md-3 col-lg-3 col-sm-12">
              <div className="footer-item">
                <h3>About Us</h3>
                <ul className="list-unstyled service-item">
                  {header?.map((menuItem, index) => (
                    <li key={index}>
                      <Link to={menuItem.menu_url} onClick={handleScrollToTop}>{menuItem.title_main}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            

            {/* Footer end */}
            <div className="col-md-3 col-lg-3 col-sm-12">
              <div className="footer-item">
                <h3>Quick Links</h3>
                <ul className="list-unstyled service-item">
                  {/* Use footer link data from the API */}
                  {footerlink?.map((linkItem, index) => (
                    <li key={index}>
                      <a href={linkItem.menu_url} 
                      target="_blank"
                      onClick={handleScrollToTop}
                      >
                        {linkItem.title_main}
                        </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-md-5 col-lg-5 col-sm-12">
              <div className="footer-item">
                <h3>Subscribe to our Newsletter</h3>
                <div className="content">
                  Subscribe To Newsletter To Stay Up To Date On Our Latest News
                </div>
                {/* <!-- subscribe --> */}
                <div className="subscribe mt-3">
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)} >
        <div className="form-group">
        <input
              type="email"
              name="email"
              className="form-control"
              placeholder="Enter your email"
              {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
            />
          
          <button type="submit" className="btn btn-warning mx-2 footerbtn">
            Subscribe
          </button>
        </div>
            {errors.email && <span className="error text-danger" style={{'fontSize': '13px'}}>Please enter a valid email address</span>}
            {showSuccessMessage && (
        <span class="register-msg-style" style={{"color": "green"}}>{subscribeMessage()}</span>
            )}
      </form>
    </div>
                {/* <!-- social --> */}
                <ul className="list-unstyled socials" >
                <li><a href="https://www.facebook.com/ReadIndiaTeam" target="_blank" className="facebook">
                <img src={facebook} style={{'height':'36px'}}/>
                </a></li>
                <li><a href="https://twitter.com/readindiateam" target="_blank" >
                <img src={twitter} style={{'height':'44px'}}/>
                </a></li>
                <li><a href="https://www.instagram.com/readindiacelebration/" target="_blank">
                <img src={instagram} style={{'height':'33px'}}/>
                </a></li>
              </ul>
              </div>
            </div>
          </div>
          <div className="company-right text-center">
            &copy; {year}. All Rights Reserved. | <a href="/disclaimer" onClick={handleScrollToTop}>Disclaimer</a>
          </div>
        </div>
        <>
          {isVisible && (
            <div
              className="back-to-top d-flex align-items-center justify-content-center active"
              onClick={handleScrollToTop}
            >
              <FaArrowUp className="scroll-to-top-icon" />
            </div>
          )}
        </>
      </footer>
    </div>
  )
}

export default Footer