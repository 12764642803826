import React, { useEffect, useRef }  from 'react';
import Slider from "react-slick";
import {getHomeRead, getHomepage} from '../store/home'
import { useDispatch, useSelector } from 'react-redux';

const Partner = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHomeRead());
  }, []);

  const homeread = useSelector((state) => state.home.HomeRead);


const settings1 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    autoplay: true,
    autoplaySpeed: 2000,
  };


  //REDUCER START
  //REDUCER END
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getHomeRead())},[])
  //   const state = useSelector(state => state.home)
  //   const homerec = useSelector(state => state.home.home_array)
  //   const homeread =state.HomeRead;
  //   const homePartner = homeread;
    console.log('qrst', homeread?.ourpartners);



return (
    <div>
      <section className="section-wrapper partners">
        <div className="container">
          <h1 className="head1 text-center">Our Partners</h1>
          <div className="owl-carousel owl-theme" id="partners">
            <Slider {...settings1}>
              {homeread?.ourpartners?.map((partner) => (
                <div className="m-1" key={partner.id}>
                  <div className="partners-list">
                    <img
                      src={homeread?.imagepath + partner.image}
                      alt={partner.image}
                      className="img-fluid"
                      align=""
                      title=""
                    />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Partner