import React, { useEffect, useRef, useState }  from 'react';
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Fade, Slide, Bounce, Zoom } from "react-awesome-reveal";
import { useDispatch, useSelector } from 'react-redux';
import {getInitiative, getInitiativePages} from '../store/home';
import BeatLoader from "react-spinners/BeatLoader";



const Initiatives = () => {

  
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInitiative());
  },[]);
  const st = useSelector(state => state.home);
  const initiative = useSelector(state => state.home.Initiative);


  useEffect(() => {
    dispatch(getInitiativePages());
  },[]);
  const stat = useSelector(state => state.home);
  const initiativepage = useSelector(state => state.home.InitiativePages);
  console.log('initiatpag', initiativepage.status);

  const navigate = useNavigate()
  const onProductClick = (link) => {
            navigate(link)
            window.scrollTo({
                top: 0,
                behavior: "smooth"
              });
           // console.log("Link", link)
        }
    

    


  return (
    <>
    {st.loaderStatus ? (
        <div className="loader-container">
        <BeatLoader loading={st.loaderStatus} size={50} color="#36d7b7" />
      </div>
      ) : (
    <div>
      
         {/* <!-- carousel --> */}
    <div className="slide-wrap inner">
    <div className="container">
      <div className="flex-grid">
      <Slide direction="up">
        <div className="page-heading wow fadeInUp">
          <h3>Initiatives</h3>
          <div className="content" dangerouslySetInnerHTML={{ __html:  initiative?.banner?.['0']?.img_desc }}>
          </div>
        </div>
        </Slide>
        <Zoom>
        <div className="page-img wow fadeInUp">
        <img src={initiative?.banner_path + initiative?.banner?.['0']?.name}  
        className="img-fluid " 
        alt={initiative?.banner?.['0']?.img_alt} title="initiatives"/>
        </div>
        </Zoom>
      </div>
    </div>
    </div>
    {/* <!-- end --> */}

    <>
    <section className="section-wrapper mt-5">
      <div className="container">
        <h1 className="head1 text-center mb-5" dangerouslySetInnerHTML={{ __html:  initiative?.description}}></h1>
        <div className="row">
        {initiative?.initiatives?.map((member, pageIndex) => (
		  <div className="tips-item text-center col-md-3"  key={pageIndex}>
            <div className="tips-item-img mt-3">
              <img alt="happiness" title="happiness" data-src={initiative?.imagepath + member.image} 
              className="img-fluid ls-is-cached lazyloaded" 
              src={initiative?.imagepath + member.image}/>
                <noscript>
                    <img src={initiative?.imagepath + member.image} className="img-fluid" alt="happiness" title="happiness"/>
                </noscript>
            </div>
            <h3>{member.title}</h3>
            <p>
            <span className="ini-summary" dangerouslySetInnerHTML={{ __html:  member.summary}}></span>
              <br/>
              {/* onClick={()=>onProductClick(`/shop/${categorySlug}/${subCategorySlug}/${plist.pro_slug}`)} */}
              <span onClick={()=>onProductClick(`/initiatives/${member.title_code}`)}>
                <button type="button" className="btn btn-warning mt-2">
                  <b>{member.button_name}</b>
                </button>
              </span>
              </p>          
        </div>
			         ))}
        </div>
      </div>
    </section>
    </>
    </div>
     )}
     </>
  )
}

export default Initiatives