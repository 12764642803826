import React, { useEffect, useState, useCallback, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import pic1 from "../Components/assets/img/reading-student.png";
import BeatLoader from "react-spinners/BeatLoader";
import { useDispatch, useSelector } from 'react-redux';

import { getRandompage } from '../store/home';

const Pagecontent = () => {

  const st = useSelector(state => state.home);
  const dispatch = useDispatch();
  const filterUrl = window.location.pathname.split("/").pop();
  const ini_page = filterUrl.split("/");

  const title_code = ini_page[0];
  console.log('filterUrl', window.location.href);
  const url = window.location.href;
  useEffect(() => {
    dispatch(getRandompage({ title_code }))

  }, [url])
  const pageContents = st.othe_page_contents;

  const banners = useSelector(state => state.home.contact_banner);
  const bannrpath = useSelector(state => state.home.bannrpath);
  return (
    <>
      {st.loaderStatus ? (
        <div className="loader-container">
          <BeatLoader loading={st.loaderStatus} size={50} color="#36d7b7" />
        </div>
      ) : (
        <div>

          <div className="slide-wrap inner">
            <div className="container">
              {banners?.map((ban, pageIndex) => (
                <div className="flex-grid">
                  <div className="page-heading wow fadeInUp animated text-color-p" style={{ "visibility": "visible", "animation-name": "fadeInUp" }}
                    dangerouslySetInnerHTML={{ __html: ban.img_desc }}>

                  </div>
                  <div className="page-img wow fadeInUp animated" style={{ "visibility": "visible", "animation-name": "fadeInUp" }}>
                    <img alt="about us" title="about us" data-src=""
                      className="img-fluid lazyloaded" src={bannrpath + ban.name} />
                    <noscript>
                      <img src={bannrpath + ban.name}
                        className="img-fluid" alt="about us" title="about us" />
                    </noscript>
                  </div>
                </div>
              ))}


            </div>
          </div>

          <div className="container">
            <p dangerouslySetInnerHTML={{ __html: pageContents?.contents }}></p>
          </div>
        </div>
      )}
    </>
  )
}

export default Pagecontent