import React from 'react'

const Teachers = () => {
  return (
    <div>
    <div className="slide-wrap inner">
    <div className="container">
      <div className="flex-grid">
        <div className="page-heading wow fadeInUp animated" style={{"visibility": "visible", "animation-name": "fadeInUp"}}>
          <h3>RIC Teachers</h3>
          <div className="content">
                      </div>
        </div>
        <div className="page-img wow fadeInUp animated" style={{"visibility": "visible", "animation-name": "fadeInUp"}}>
          <img alt="about us" 
          title="about us" 
          data-src="https://readindia.info/wp-content/uploads/2021/09/initiatives.svg" 
          className="img-fluid ls-is-cached lazyloaded" 
          src="https://readindia.info/wp-content/uploads/2021/09/initiatives.svg"/>
            <noscript>
                <img src="https://readindia.info/wp-content/uploads/2021/09/initiatives.svg" 
                className="img-fluid" 
                alt="about us" title="about us"/>
                </noscript>
        </div>
      </div>
    </div>
    </div>

    <div className="container">
    <div className="ric_teach">
<div className="container my-4">
<div className="row">
<div className="col-md-7">
<h3>RIC-Teachers 2020 (Dated: 18th Jan 2021):</h3>
<p>What Breadth means to Body, Education is to Success; What Education is to Success, Leadership is to a Nation.</p>
<p><b style={{"font-style": "inherit"}}>Winners of RIC Teachers 2020 initiative-</b></p>
<ul>
<li>Dawandeep Kaur; Topic – Role of Education in independent thinking; School Name: SBS – Ghungrali Rajputtan, Punjab</li>
<li>Paramjit Kaur; Topic – Difference between Shikshak &amp; Guru;&nbsp; School Name: SBS – Gosal, Punjab</li>
<li>Sandeep Kaur; Topic – Gaps in our education system;&nbsp; School Name: SBS – Gosal, Punjab</li>
<li>Mandeep Kaur;&nbsp; Topic – Measure of Education;&nbsp; School Name: SBS – Mithewal, Punjab</li>
</ul>
</div>
<div className="col-md-5">
<img decoding="async" width="800" height="506" 
alt="" loading="lazy" 
data-srcset="https://readindia.info/wp-content/uploads/2021/04/ric-teachers.jpg 800w, https://readindia.info/wp-content/uploads/2021/04/ric-teachers-300x190.jpg 300w, https://readindia.info/wp-content/uploads/2021/04/ric-teachers-768x486.jpg 768w" 
data-src="https://readindia.info/wp-content/uploads/2021/04/ric-teachers.jpg" data-sizes="(max-width: 800px) 100vw, 800px" className="img-fluid ls-is-cached lazyloaded" src="https://readindia.info/wp-content/uploads/2021/04/ric-teachers.jpg" 
sizes="(max-width: 800px) 100vw, 800px" 
srcset="https://readindia.info/wp-content/uploads/2021/04/ric-teachers.jpg 800w, https://readindia.info/wp-content/uploads/2021/04/ric-teachers-300x190.jpg 300w, https://readindia.info/wp-content/uploads/2021/04/ric-teachers-768x486.jpg 768w"/>
    <noscript>
        <img decoding="async" width="800" height="506" 
        src="https://readindia.info/wp-content/uploads/2021/04/ric-teachers.jpg" 
        alt="" loading="lazy" 
        srcset="https://readindia.info/wp-content/uploads/2021/04/ric-teachers.jpg 800w, https://readindia.info/wp-content/uploads/2021/04/ric-teachers-300x190.jpg 300w, https://readindia.info/wp-content/uploads/2021/04/ric-teachers-768x486.jpg 768w" sizes="(max-width: 800px) 100vw, 800px" 
         className="img-fluid"/></noscript>
</div>
</div>
<p style={{"font-size": "15px", "font-style": "normal"}}><b>Read India Celebration for Teachers</b></p>
<p style={{"font-size": "15px", "font-style": "normal", "font-weight": "400"}}>Nominated book for the year 2020 – Leading Self 2 Leading Others (Download Free Ebook)</p>
<p style={{"font-size": "15px", "font-style": "normal", "font-weight": "400"}}>*Gurus who build societies / cultures are the most valuable persons in a nation</p>
<p><b>Themes / Topics:</b></p>
<p>– Difference between a Shikshak and a Guru</p>
<p>– Measure of Education</p>
<p>– Gyan (Knowedge) based Society &amp; Culture Vs Commerce based Society &amp; Culture</p>
<p>– Role of Education in Enabling Independent Thinking</p>
<p>– Difference between Shiksha (Shikshit) and Vidya (Vidyavan)</p>
<p>– Gaps in our Education System</p>
<blockquote data-aos="fade-left" data-aos-duration="1000">
<p>Teachers can choose one or more themes / topics from the list above and read books / research for 4 weeks in the month of April and prepare a journal / paper (not more than 1500 words) and send it via email to readindiacelebration2020@gmail.com before 30th June 2020 with subject:</p>
<p><b>RIC2020 – Teachers – Theme Name – School Name – Participant Name</b></p>
<p>No entry fees, all teachers / heads of the school can participate.</p>
<p>Top 3 papers from 6 categories / themes will be selected for a presentation in the teachers conclave / town hall on education to be organized in the month of June / July 2020 (Details TBC).</p>
<p>Best 6 presenters / papers as a token of gratitude will be awarded with a cash prize of Rs. 10,000 by Read India.</p>
</blockquote>
<p><b>&nbsp;Congratulations &amp; thank you for your patience. Request all winners to please contact Raghuram Ananthoj @ Cell# 9441456061</b></p>
</div>
</div>
</div>
    </div>
  )
}

export default Teachers